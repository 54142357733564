/* eslint-disable max-len */
export default {
  achievements: {
    mainTitle: "Мои достижения",
    infoTitle: "Кешбэк за покупку!",
    infoDesc: "Покупайте в заведениях ниже и повышайте кешбэк",
    infoBtn: "Узнать больше",
  },
  achievementsList: {
    title: "– Заведения с меткой",
    desc: "За каждую покупку вы получаете больше баллов",
  },
  achievementItem: {
    yourCashback: "Ваш кешбэк",
    still: "ещё",
    pointsTill: "баллов до",
    burnsDownRu: "Сгорает",
    burnsDownKk: "",
    cashbackTillRu: "Кешбэк до",
    cashbackTillKz: "",
  },
  myGrade: {
    yourCashback: "Ваш кешбэк",
    nextGrade: "Следующий уровень",
    tillRu: "до",
    tillKz: "",
    stillRu: "еще",
    toKz: "",
    stillKz: "",
    today: "сегодня",
    levelWasSaved: "Уровень защищен",
    letsSaveLevel: "Сохраните уровень",
    youSavedLevelTill1: "Вы сохранили уровень до",
    youSavedLevelTill2: "",
    activateLevel: "Активируйте уровень",
  },
  details: {
    forNextLevel: "Чтобы перейти на следующий уровень",
    forNextLevel2: "",
    still1: "накопите ещё",
    still2: "",
    forCurrentLevel: "Вы продолжите получать",
    forCurrentLevel2: "если накопите ещё",
    forCurrentLevel3: "",
    forCurrentLevel4: "Если накопить меньше",
    forCurrentLevel5: ", вы вернетесь",
    forCurrentLevel6: "на предыдущий уровень",
    stillKz: "",
    savedLevel: "Выполнено! Ваш кешбэк",
    savedLevel2: "защищён",
    savedLevel3: "на следующие",
    savedLevel4: "",
    days: "",
    info1: "Получайте баллы с каждой покупки",
    info2: "в заведениях (1 балл = 100 тенге)",
    info3: "Больше баллов - выше уровень кешбэка",
    info4: "С момента получения уровень действует ",
    info5: "",
  },
  faq: {
    title1: "Повышенный кешбэк с первой покупки",
    desc1: "Теперь вы решаете какой размер кешбэка получить!",
    desc2:
      "Чем больше покупок в заведении, тем больше уровень вашего кешбэка в нем. При совершении первой покупки вы можете сразу получить новый уровень кешбэка. Как? Оплатите на необходимое количество баллов. Количество баллов для нового уровня можно посмотреть в карточке заведения.",
    desc3: "1 балл = 100 тенге",
    title2: "Уровень действует определённое количество дней",
    desc4:
      "В карточке заведения указано до какой даты уровень будет активным. В течение этого времени вы можете повысить свой кешбэк до нового уровня.",
    title3: "За каждое действие в заведении вы получаете баллы",
    desc5:
      "Сейчас мы готовим несколько активностей, за которые вы можете получать дополнительные баллы. Будет интересно :)",
    title4: "Защита от падения уровня",
    desc6:
      "Для продления действующего уровня кешбэка на ещё один срок вам необходимо накопить баллы. В случае если ваших баллов будет недостаточно для продления, то вы попадете на предыдущий уровень. Баллы для защиты уровня не списываются во время срока его действия.",
  },
};
