import cookie from "cookie"; // использовать на стейдже
import initializeHomePage from "@/service/initializePages/initializeHomePage";
// import { useRoute } from "vue-router"; // использовать локально

// использовать на стейдже
function getCookie(name) {
  const nameLenPlus = name.length + 1;
  return (
    document.cookie
      .split(";")
      .map((c) => c.trim())
      .filter((cookie) => {
        return cookie.substring(0, nameLenPlus) === `${name}=`;
      })
      .map((cookie) => {
        return decodeURIComponent(cookie.substring(nameLenPlus));
      })[0] || null
  );
}
const queryParams = new URLSearchParams(location.search);
export const initAuth = async () => {
  if (queryParams.get("track_id") && !getCookie("token")) {
    await initializeHomePage(queryParams.get("track_id"));
  } else if (!queryParams.get("track_id") && getCookie("token")) {
    await initializeHomePage(queryParams.get("track_id"));
    // document.cookie = cookie.serialize("token", "");
  }
};
export const trackId = () => {
  return queryParams.get("track_id");
};
// использовать локально
// const route = useRoute();
// export const initAuth = () => {
//   initializeHomePage(route?.query?.track_id);
// };
