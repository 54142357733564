import isValidApiData from "@/utils/functions/is-valid-api-data";

interface IApiProxyErrorData {
  errors?: {
    id: string;
    status: string;
    code: string;
    title: string;
    detail: string;
  }[];
}

/**
 * Type Guard для ApiProxy-ошибки
 */
const isApiProxyErrorData = (
  data: IApiProxyErrorData | unknown
): data is IApiProxyErrorData => isValidApiData(data, "errors");

export { IApiProxyErrorData, isApiProxyErrorData };
