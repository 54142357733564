/**
 * Проверяет валидность ответа запроса API
 */
export default function (data: unknown, key: string): boolean {
  return !!(
    data &&
    typeof data === "object" &&
    Object.prototype.hasOwnProperty.call(data, key)
  );
}
