<template>
  <div class="achievements-list">
    <div class="achievements-list__title-wrapper">
      <img src="https://object.pscloud.io/loyalty/grading-front/x2.png" alt="" />
      <div>
        <div class="achievements-list__title">{{$t('achievementsList.title')}}</div>
        <div class="achievements-list__desc">
          {{$t('achievementsList.desc')}}
        </div>
      </div>
    </div>
    <div class="achievements-list__list">
        <AchievementItem
          v-for="restaurant in sortedRestaurantsList"
          :key="restaurant.id"
          :restaurantInfo="restaurant"
          :currentLang="currentLang"
          @click="goToDetails(restaurant.id)"
        />
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from "vue";
import AchievementItem from "../components/AchievementItem.vue";
import { useGradingList } from "@/store/gradingList.store";
import { useRouter } from "vue-router";
import i18n from "@/service/i18n";
import { trackId } from "@/plugins/checkAuth";//использовать на стейдже

export default defineComponent({
  name: "AchievementsList",
  components: {
    AchievementItem,
  },
  data: function () {
    return {};
  },
  setup() {
    const gradingListStore = useGradingList();
    const restaurantsList = computed(() => gradingListStore.restaurantsList);
    const router = useRouter()
    const sortedRestaurantsList = computed(() => gradingListStore.sortedRestaurantsList);

    async function goToDetails (id) {
      // await router.push({ name: "Details", params:{id} }); //использовать локально
      await router.push({ name: "Details", params:{id}, query: {track_id: trackId} }); //использовать на стейдже
    }
    let currentLang = i18n.global.locale;
    return {
      restaurantsList,
      sortedRestaurantsList,
      goToDetails,
      currentLang
    }
  }
});
</script>

<style lang="scss">
.achievements-list {
  background: #ffffff;
  border-radius: 32px 32px 0 0;
  padding: 18px 12px 0 12px;
  height: 100%;
  &__title-wrapper {
    flex-direction: row;
    align-items: center;
    padding: 0 10px 20px 0;
    display: none;
  }
  &__title-wrapper > img {
    margin-right: 10px;
    height: 20px;
  }
  &__title {
    color: #000000;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.005em;
  }
  &__desc {
    color: #a4a2b7;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.005em;
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    & > div {
      width: calc(100% - 6px);
      margin-bottom: 24px;
    }
    & > div:nth-child(odd) {
      margin-right: 6px;
    }
    & > div:nth-child(even) {
      margin-left: 6px;
    }
  }
}
</style>
