import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import HomePage from "@/pages/HomePage.vue";
import DetailsPage from "@/pages/DetailsPage.vue";
import FAQPage from "@/pages/FAQPage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: HomePage,
  },
  {
    path: "/details/:id",
    name: "Details",
    component: DetailsPage,
  },
  {
    path: "/faq",
    name: "FAQ",
    component: FAQPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;

const testHash = "gx8w7kD";
