import { createI18n } from "vue-i18n";
import lang from "@/lang";

const params = new URLSearchParams(window.location.search);
let initialLang = params.get("language");
// if (!initialLang) initialLang = "kk";
if (!initialLang && localStorage.getItem("language"))
  initialLang = localStorage.getItem("language");
else if (!initialLang) initialLang = "kk";

const languages = ["ru", "kk"];
if (initialLang) {
  if (!languages.includes(initialLang)) {
    initialLang = "kk";
  }
}

const i18n = createI18n({
  locale: initialLang as string,
  messages: lang,
});
localStorage.setItem("language", i18n.global.locale);
export default i18n;
