<template>
  <div class="faq">
    <nav class="navigation">
      <router-link @click="$router.go(-1)" class="navigation__btn" to><img src="https://object.pscloud.io/loyalty/grading-front/arrow.svg" alt="" /></router-link>
      <div class="navigation__title">F.A.Q.</div>
    </nav>
    <div class="faq__list">
      <div class="faq__item">
        <img src="https://object.pscloud.io/loyalty/grading-front/faq-percent.svg" alt="" />
        <div class="faq__title">{{$t('faq.title1')}}</div>
        <div class="faq__desc">{{$t('faq.desc1')}}</div>
        <div class="faq__desc">{{$t('faq.desc2')}}</div>
        <div class="faq__desc">{{$t('faq.desc3')}}</div>
      </div>
      <div class="faq__item">
        <img src="https://object.pscloud.io/loyalty/grading-front/faq-time.svg" alt="" />
        <div class="faq__title">{{$t('faq.title2')}}</div>
        <div class="faq__desc">{{$t('faq.desc4')}}</div>
      </div>
      <div class="faq__item">
        <img src="https://object.pscloud.io/loyalty/grading-front/faq-coins.svg" alt="" />
        <div class="faq__title">{{$t('faq.title3')}}</div>
        <div class="faq__desc">{{$t('faq.desc5')}}</div>
      </div>

      <div class="faq__item">
        <img src="https://object.pscloud.io/loyalty/grading-front/faq-guard.svg" alt="" />
        <div class="faq__title">{{$t('faq.title4')}}</div>
        <div class="faq__desc">{{$t('faq.desc6')}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "FAQPage",
});
</script>

<style scoped lang="scss">
.faq {
  background-color: #F1F2F7;
  &__list {
    margin-top: 64px;
    padding-top: 20px;
    padding-bottom: 18px;
  }
  &__item {
    background: #FFFFFF;
    border-radius: 32px;
    padding: 24px 16px;
    margin-bottom: 12px;
    & > img {
      display: block;
      margin: 0 auto 12px auto;
      width: 56px;
      height: 56px;
    }
  }
  &__title {
    color: #000000;
    letter-spacing: 0.015em;
    margin-bottom: 12px;
    font-weight: 900;
    font-size: 24px;
    line-height: 115%;
    text-align: center;
  }
  &__desc {
    color: #7B7A8C;
    letter-spacing: 0.005em;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }
}
</style>
