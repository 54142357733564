
import { computed, defineComponent, toRefs } from "vue";
import CircleProgress from "vue3-circle-progress";
import { useGrading } from "@/store/grading.store";

export default defineComponent({
  name: "GradePercent",
  props: {
    gradePercent: {
      type: Number,
      required: true,
    },
    partialValue: {
      type: Number,
      required: true,
    },
    totalValue: {
      type: Number,
      required: true,
    },
  },
  components: {
    CircleProgress,
  },
  setup: function (props) {
    const gradingStore = useGrading();
    const points = computed(() => gradingStore.points);

    const { partialValue } = toRefs(props);
    const { totalValue } = toRefs(props);
    const currentPercent = computed(() => {
      if (!points.value) return null;
      return (100 * partialValue.value) / totalValue.value;
    });

    return {
      currentPercent,
    };
  },
});
