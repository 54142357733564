import { IPayedRestaurant } from "@/store/grading.store";
import apiClient from "@/utils/apiClient";
import cookie from "cookie";

const gradingList = async (): Promise<IPayedRestaurant> => {
  const cookies = cookie.parse(document.cookie);
  const token = cookies.token;
  let res;
  if (token) {
    res = await apiClient.get(`/member-ladder/grading-list-private`);
  } else {
    res = await apiClient.get(`/member-ladder/grading-list`);
  }
  return res?.data.data;
};
// моковые данные
// const gradingList = () => {
//   return {
//     data: [
//       {
//         type: "string",
//         id: "1",
//         attributes: {
//           id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//           title: "Название 1",
//           logo: "https://randd-dev.object.pscloud.io/logo/d6/26/594e9550f381a70567d5a6ca9f0a.png",
//           current_level: "#1",
//           points: 100,
//           available_levels: [
//             {
//               id: "#1", //от 0 до 200
//               value: 1,
//               threshold: 0,
//             },
//             {
//               id: "#2", //от 200 до 300
//               value: 2,
//               threshold: 200,
//             },
//             {
//               id: "#3", //от 300 до 400
//               value: 3,
//               threshold: 300,
//             },
//             {
//               id: "#4", //от 400 до 500
//               value: 4,
//               threshold: 400,
//             },
//             {
//               id: "#5", // свыше 500
//               value: 5,
//               threshold: 500,
//             },
//           ],
//           is_member: true,
//         },
//       },
//       {
//         type: "string",
//         id: "2",
//         attributes: {
//           id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//           title: "Название 2",
//           logo: "https://randd-dev.object.pscloud.io/logo/d6/26/594e9550f381a70567d5a6ca9f0a.png",
//           current_level: "#2",
//           points: 250,
//           available_levels: [
//             {
//               id: "#1", //от 0 до 200
//               value: 1,
//               threshold: 0,
//             },
//             {
//               id: "#2", //от 200 до 300
//               value: 2,
//               threshold: 200,
//             },
//             {
//               id: "#3", //от 300 до 400
//               value: 3,
//               threshold: 300,
//             },
//             {
//               id: "#4", //от 400 до 500
//               value: 4,
//               threshold: 400,
//             },
//             {
//               id: "#5", // свыше 500
//               value: 5,
//               threshold: 500,
//             },
//           ],
//           is_member: true,
//         },
//       },
//       {
//         type: "string",
//         id: "3",
//         attributes: {
//           id: "3",
//           title: "Название 3",
//           logo: "https://randd-dev.object.pscloud.io/logo/d6/26/594e9550f381a70567d5a6ca9f0a.png",
//           current_level: "#3",
//           points: 310,
//           available_levels: [
//             {
//               id: "#1", //от 0 до 200
//               value: 1,
//               threshold: 0,
//             },
//             {
//               id: "#2", //от 200 до 300
//               value: 2,
//               threshold: 200,
//             },
//             {
//               id: "#3", //от 300 до 400
//               value: 3,
//               threshold: 300,
//             },
//             {
//               id: "#4", //от 400 до 500
//               value: 4,
//               threshold: 400,
//             },
//             {
//               id: "#5", // свыше 500
//               value: 5,
//               threshold: 500,
//             },
//           ],
//           is_member: true,
//         },
//       },
//       {
//         type: "string",
//         id: "4",
//         attributes: {
//           id: "4",
//           title: "Название 4",
//           logo: "https://randd-dev.object.pscloud.io/logo/d6/26/594e9550f381a70567d5a6ca9f0a.png",
//           current_level: "#5",
//           points: 510,
//           available_levels: [
//             {
//               id: "#1", //от 0 до 200
//               value: 1,
//               threshold: 0,
//             },
//             {
//               id: "#2", //от 200 до 300
//               value: 2,
//               threshold: 200,
//             },
//             {
//               id: "#3", //от 300 до 400
//               value: 3,
//               threshold: 300,
//             },
//             {
//               id: "#4", //от 400 до 500
//               value: 4,
//               threshold: 400,
//             },
//             {
//               id: "#5", // свыше 500
//               value: 5,
//               threshold: 500,
//             },
//           ],
//           is_member: true,
//         },
//       },
//       {
//         type: "string",
//         id: "5",
//         attributes: {
//           id: "5",
//           title: "Название 4",
//           logo: "https://randd-dev.object.pscloud.io/logo/d6/26/594e9550f381a70567d5a6ca9f0a.png",
//           current_level: "#5",
//           points: 510,
//           available_levels: [
//             {
//               id: "#1", //от 0 до 200
//               value: 1,
//               threshold: 0,
//             },
//             {
//               id: "#2", //от 200 до 300
//               value: 2,
//               threshold: 200,
//             },
//             {
//               id: "#3", //от 300 до 400
//               value: 3,
//               threshold: 300,
//             },
//             {
//               id: "#4", //от 400 до 500
//               value: 4,
//               threshold: 400,
//             },
//             {
//               id: "#5", // свыше 500
//               value: 5,
//               threshold: 500,
//             },
//           ],
//           is_member: true,
//         },
//       },
//     ],
//   };
// };
export const gradingListRepository = {
  gradingList,
};
