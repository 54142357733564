<template>
  <div class="achievement-item">
    <div class="achievement-item__wrapper">
      <img
        v-if="logo"
        class="achievement-item__logo"
        :src="logo"
        alt="logo"
      />
      <Skeleton
        v-else
        :style="{
                margin: '0 auto 19px auto',
                display: 'block',
                width: '96px',
                height: '96px',
                borderRadius: '100%',
              }"
      />
      <ProgressBar
        v-if="isMember && (receivedPoints || receivedPoints === 0) && nextGradeLevelThreshold"
        :partialValue="Number(receivedPoints) ?? 0"
        :totalValue="Number(nextGradeLevelThreshold) ?? 0" />
    </div>
    <div class="achievement-item__title" v-if="isMember && currentLevelPercent">{{$t('achievementItem.yourCashback')}} {{currentLevelPercent}}%</div>
    <div class="achievement-item__title" v-if="!isMember">{{$t('achievementItem.cashbackTillRu')}} {{maxLevelPercent}}% {{$t('achievementItem.cashbackTillKz')}}</div>
    <Skeleton
      v-else-if="!currentLevelPercent && isMember"
      :style="{
                width: '100%',
                height: '18px',
                borderRadius: '24px',
                margin: '8px 0 4px 0'
              }"
    />
    <div v-if="currentLang === 'ru'">
      <div class="achievement-item__desc" v-if="receivedPointsTillNextLevel && isMember && daysBeforeLevelBurnsDown > 10">{{$t('achievementItem.still')}} {{receivedPointsTillNextLevel}} {{$t('achievementItem.pointsTill')}} {{nextGradeLevelObj.value}}%</div>
    </div>
    <div v-if="currentLang === 'kk'">
      <div class="achievement-item__desc" v-if="receivedPointsTillNextLevel && isMember && daysBeforeLevelBurnsDown > 10">{{nextGradeLevelObj.value}}% {{$t('achievementItem.still')}} {{receivedPointsTillNextLevel}} {{$t('achievementItem.pointsTill')}}</div>
    </div>
    <div class="achievement-item__desc" v-if="!isMember">{{title}}</div>
    <div class="achievement-item__desc-warn" v-if="isMember && daysBeforeLevelBurnsDown <= 10">{{$t('achievementItem.burnsDownRu')}} {{downgradeDate}} {{$t('achievementItem.burnsDownKk')}}</div>
  </div>
</template>

<script>
// import { Analytics, events } from "../utils/analytics";
import ProgressBar from "./ProgressBar.vue";
import { defineComponent, reactive, toRef } from "vue";
import Skeleton from "@/components/Skeleton.vue";
import dayjs from "dayjs";
require('dayjs/locale/ru');
require('dayjs/locale/kk');

export default defineComponent({
  name: "AchievementItem",
  components: {
    ProgressBar,
    Skeleton
  },
  data: function () {
    return {};
  },
  props: {
    restaurantInfo: {
      type: Object,
      required: true
    },
    currentLang: {
      type: String,
      required: true
    }
  },
  setup(props) {
    let restaurant = reactive(toRef(props, 'restaurantInfo'));
    const currentLevel = reactive(toRef(props, 'restaurantInfo')).value.attributes.current_level;
    const logo = reactive(toRef(props, 'restaurantInfo')).value.attributes.logo;
    const title = reactive(toRef(props, 'restaurantInfo')).value.attributes.title;
    const availableLevels = reactive(toRef(props, 'restaurantInfo')).value.attributes.available_levels;
    const currentLevelPercent = availableLevels.find((el) => el.id === currentLevel)?.value;
    const maxLevelPercent = availableLevels[availableLevels.length-1].value;
    const currentLevelInArrayIndex = availableLevels.findIndex((x) => {
      return x.value === currentLevelPercent;
    }) || 0;
    const nextGradeLevelObj = availableLevels[currentLevelInArrayIndex + 1];
    let receivedPoints;
    receivedPoints = reactive(toRef(props, 'restaurantInfo')).value.attributes.points;

    let receivedPointsTillNextLevel;
    if(!nextGradeLevelObj) receivedPointsTillNextLevel = undefined;
    else receivedPointsTillNextLevel = nextGradeLevelObj?.threshold - receivedPoints;

    let nextGradeLevelThreshold;
    if(!nextGradeLevelObj?.threshold) {
      nextGradeLevelThreshold = availableLevels[currentLevelInArrayIndex]?.threshold;
      if(receivedPoints > nextGradeLevelThreshold) receivedPoints = nextGradeLevelThreshold;
    }
    else {
      nextGradeLevelThreshold = nextGradeLevelObj?.threshold
    }

    const isMember = reactive(toRef(props, 'restaurantInfo')).value.attributes.is_member;

    let downgradeDate;
    let currentLang = localStorage.getItem('language');
    if(currentLang==='ru') {
      downgradeDate = dayjs(reactive(toRef(props, 'restaurantInfo')).value.attributes.downgrade_date).locale('ru').format('D MMMM');
    } else if(currentLang==='kk') {
      downgradeDate = dayjs(reactive(toRef(props, 'restaurantInfo')).value.attributes.downgrade_date).locale('kk').format('D MMMM');
    }

    if (!downgradeDate) {
      downgradeDate = '';
    }
    if(downgradeDate.includes("қаңтар") || downgradeDate.includes("ақпан") || downgradeDate.includes("наурыз") || downgradeDate.includes("мамыр") || downgradeDate.includes("маусым") || downgradeDate.includes("тамыз") || downgradeDate.includes("қазан") || downgradeDate.includes("қараша") || downgradeDate.includes("желтоқсан")) {
      downgradeDate = downgradeDate + 'да';
    }
    else if(downgradeDate.includes("сәуір" || "шілде")) {
      downgradeDate = downgradeDate + 'де';
    }
    else if(downgradeDate.includes("қыркүйек")) {
      downgradeDate = downgradeDate + 'те';
    }

    let currentDate = dayjs(new Date()).format('YYYY-MM-DD');

    let daysBeforeLevelBurnsDown = Math.ceil((Date.parse(reactive(toRef(props, 'restaurantInfo')).value.attributes.downgrade_date) - Date.parse(currentDate)) / (1000 * 3600 * 24));
    return {
      restaurant,
      currentLevel,
      currentLevelPercent,
      receivedPointsTillNextLevel,
      receivedPoints,
      nextGradeLevelThreshold,
      logo,
      nextGradeLevelObj,
      isMember,
      downgradeDate,
      daysBeforeLevelBurnsDown,
      title,
      maxLevelPercent
    }
  }
});
</script>

<style lang="scss">
.achievement-item {
  width: 100%;
  &__wrapper {
    background: #f1f2f7;
    border-radius: 24px;
    padding: 36px 12px 12px 12px;
    min-height: 171px;
    & .progress-bar__save {
      display: none;
    }
  }
  &__logo {
    margin: 0 auto 19px auto;
    display: block;
    width: 96px;
    height: 96px;
  }
  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.005em;
    color: #000000;
    margin-bottom: 4px;
    margin-top: 8px;
  }
  &__desc,
  &__desc-warn {
    color: #7b7a8c;
    letter-spacing: 0.005em;
    font-size: 12px;
    line-height: 14px;
  }
  &__desc-warn {
    color: #ff511b;
  }
}
</style>
