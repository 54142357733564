<template>
  <div class="progress-bar">
    <div class="progress-bar__value" :style="{width: `${progress}%`,}"></div>
    <div class="progress-bar__save" v-if="pointsToSave && !checkIfLastLevel" :style="{ left: showPointsToSaveOnProgressBar + '%' }">
      <div class="progress-bar__save-streak"></div>
      <img src="https://object.pscloud.io/loyalty/grading-front/guard-icon.svg" alt="">
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from "vue";
import { useGrading } from "@/store/grading.store";

export default defineComponent({
  name: "ProgressBar",
  props: {
    partialValue: {
      type: Number,
      required: true
    },
    totalValue: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      progress: "0",
    };
  },
  mounted() {
    setTimeout(() => {
      this.progress = this.renderingProgressbarPercent().toFixed();
    }, 100)
  },
  methods: {
    percentage(partialValue, totalValue) {
      return (100 * partialValue) / totalValue;
    },
    renderingProgressbarPercent() {
      return this.percentage(this.partialValue, this.totalValue);
    },
  },
  setup() {
    const gradingStore = useGrading();
    const pointsToSave = computed(() => gradingStore.pointsToSave);

    const points = computed(() => gradingStore.points);
    const nextThreshold = computed(() => gradingStore.nextThreshold);
    let totalPoints = pointsToSave.value + points.value;
    let showPointsToSaveOnProgressBar = (totalPoints * 100) /nextThreshold.value;
    const checkIfLastLevel = computed(() => gradingStore.checkIfLastLevel);
    return {
      pointsToSave,
      showPointsToSaveOnProgressBar,
      totalPoints,
      checkIfLastLevel
    }
  }
});
</script>

<style lang="scss">
.progress-bar {
    background: #d4d3df;
    justify-content: flex-start;
    border-radius: 12px;
    align-items: center;
    position: relative;
    display: flex;
    height: 8px;
    width: 100%;
  &__value {
    border-radius: 100px;
    background: #ff511b no-repeat;
    background-size: cover;
    height: 8px;
    width: 0%;
    transition: width 3s ease;
    -webkit-transition: width 3s ease;
    -moz-transition: width 3s ease;
  }
  &__save {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__save-streak {
    height: 8px;
    width: 1px;
    background: #FF511B;
  }

  &__save > img {
    margin-top: 10px;
    width: 14px;
    height: 14px;
  }
}
</style>
