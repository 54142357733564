/* eslint-disable max-len */
export default {
  achievements: {
    mainTitle: "Менің жетістіктерім",
    infoTitle: "Сатып алу үшін кешбэк!",
    infoDesc: "Төмендегі мекемелерден сатып алу арқылы кешбэкті үлкейтіңіз",
    infoBtn: "Толығырақ",
  },
  achievementsList: {
    title: "– Белгісі бар мекемелер",
    desc: "Әр сатып алу үшін көбірек балл алыңыз",
  },
  achievementItem: {
    yourCashback: "Сіздің кешбэк",
    still: "тағы",
    pointsTill: "балл",
    burnsDownRu: "",
    burnsDownKk: "күйеді",
    cashbackTillRu: "",
    cashbackTillKz: "дейін кешбэк",
  },
  myGrade: {
    yourCashback: "Сіздің кешбэк",
    nextGrade: "Келесі деңгей",
    tillRu: "",
    tillKz: "дейін",
    stillRu: "",
    toKz: "дейін",
    stillKz: "тағы",
    today: "бүгін",
    levelWasSaved: "Деңгей қорғалған",
    letsSaveLevel: "Деңгейді сақтаңыз",
    youSavedLevelTill1: "Сіз",
    youSavedLevelTill2: "дейін деңгейді сақтадыңыз",
    activateLevel: "Деңгейді іске қосыңыз",
  },
  details: {
    forNextLevel: "Келесі деңгей",
    forNextLevel2: "алу үшін ",
    still1: "тағы",
    still2: "жинаңыз",
    forCurrentLevel: "Егер тағы",
    forCurrentLevel2: "жинасаңыз,",
    forCurrentLevel3: "алуды жалғастырасыз",
    forCurrentLevel4: "Егер",
    forCurrentLevel5: "аз жиналса, сіз алдыңғы деңгейге",
    forCurrentLevel6: "ораласыз",
    stillKz: "сақтау үшін тағы",
    savedLevel: "Орындалды! Сіздің",
    savedLevel2: "кешбэгіңіз",
    savedLevel3: "келесі",
    savedLevel4: "күнге сақталды.",
    days: "күн қалды",
    info1: "Мекемелердегі әрбір сатып алудан балл",
    info2: "алыңыз (1 балл  = 100 теңге)",
    info3: "Көбірек балл - жоғарырақ кешбэк",
    info4: "Алынған сәттен бастап деңгей ",
    info5: " күнге жарамды",
  },
  faq: {
    title1: "Бірінші сатып алудан бастап кэшбэк үлкейту",
    desc1: "Енді сіз қанша кэшбэк алу керектігін шешесіз!",
    desc2:
      "Мекемеде неғұрлым көп сатып алу болса, ондағы кэшбэк деңгейі соғұрлым жоғары болады.  Бірінші сатып алуды жасаған кезде, сіз кэшбэктің жаңа деңгейін бірден ала аласыз.  Қалай?  Қажетті ұпай санына төлеңіз. Жаңа деңгей үшін ұпайлар санын мекеменің картасынан көруге болады.",
    desc3: "1 ұпай = 100 теңге",
    title2: "Деңгей белгілі бір күндер санына жарамды",
    desc4:
      "Мекеме картасы деңгейдің қай күнге дейін белсенді болатынын көрсетеді.  Осы уақыт ішінде сіз кешбэкті жаңа деңгейге көтере аласыз.",
    title3: "Мекемедегі әрбір әрекет үшін сіз ұпай аласыз",
    desc5:
      "Қазір біз қосымша ұпайлар алуға болатын бірнеше қызықты затты дайындап жатырмыз. Біраз күте тұрыңыз :)",
    title4: "Деңгейдің төмендеуінен қорғау",
    desc6:
      "Ағымдағы кэшбэк деңгейін басқа кезеңге ұзарту үшін ұпайларды жинақтау қажет.  Ұпайларыңыз жаңартуға жеткіліксіз болса, сіз алдыңғы деңгейге өтесіз.  Деңгейді қорғауға арналған ұпайлар оның әрекет ету мерзімі ішінде шегерілмейді.",
  },
};
