<template>
  <div class="my-achievements">
    <nav class="navigation">
      <button class="navigation__btn" @click="backToMain">
        <img src="https://object.pscloud.io/loyalty/grading-front/arrow.svg" alt="" />
      </button>
      <div class="navigation__title">{{$t('achievements.mainTitle')}}</div>
    </nav>
    <div class="my-achievements__info">
      <div>
        <div class="my-achievements__info-title">{{$t('achievements.infoTitle')}}</div>
        <div class="my-achievements__info-desc">
          {{$t('achievements.infoDesc')}}
        </div>
        <button class="my-achievements__info-btn" @click="goToFAQ()">{{$t('achievements.infoBtn')}}</button>
      </div>
      <img
        class="my-achievements__info-img"
        src="https://object.pscloud.io/loyalty/grading-front/three-stars.png"
        alt=""
      />
    </div>
    <AchievementsList />
  </div>
</template>

<script>
import { Analytics, events } from "@/service/analytics";
import AchievementsList from "../components/AchievementsList.vue";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Achievements",
  components: {
    AchievementsList,
  },
  setup: function () {
    const backToMain = () => {
      window.RahmetApp && window.RahmetApp.backToRahmetApp && window.RahmetApp.backToRahmetApp();
    }
    const router = useRouter()
    function goToFAQ () {
      Analytics.logEvent(events.GRADING_GUIDE_OPEN);
      router.push({ name: "FAQ"});
    }
    return {
      backToMain,
      goToFAQ,
    }
  }
});
</script>

<style lang="scss">
.my-achievements {
  background: #f1f2f7;
  padding-top: 64px;
  overflow-x: hidden;
  &__info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #ffffff;
    border-radius: 24px;
    box-shadow: 0 92px 92px rgba(0, 0, 0, 0.05),
      0 3px 38.4354px rgba(0, 0, 0, 0.0334858),
      0px -3px 20.5494px rgba(0, 0, 0, 0.06),
      0px 5.79369px 11.5198px rgba(0, 0, 0, 0.0189792),
      0px 2.40368px 6.11809px rgba(0, 0, 0, 0.0132742),
      0px 0.705169px 2.54588px rgba(0, 0, 0, 0.00743532);
    padding: 20px 16px;
    margin: 16px 8px 40px 8px;
    align-items: center;
    position: relative;
    z-index: 0;
  }
  &__info-title {
    color: #000000;
    font-weight: 900;
    font-size: 18px;
    line-height: 110%;
    margin-bottom: 4px;
  }
  &__info-desc {
    color: #a4a2b7;
    font-size: 12.16px;
    line-height: 14px;
    margin-bottom: 12px;
    font-weight: 500;
    max-width: 200px;
  }
  &__info-btn {
    color: #ffffff;
    font-size: 16px;
    line-height: 18px;
    padding: 8px 30px;
    background: #000000;
    border-radius: 12px;
    border: none;
    font-weight: 500;
  }
  &__info-img {
    width: 192px;
    height: 192px;
    position: absolute;
    right: -30px;
    top: -15px;
  }
}
.navigation {
  padding: 18px 0 26px 0;
  background-color: #ffff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  &__btn {
    border: none;
    background-color: transparent;
    position: absolute;
    left: 18px;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    outline: none;
  }
  &__title {
    color: #000000;
    font-size: 18px;
    line-height: 110%;
    text-align: center;
    font-weight: 900;
    max-width: 240px;
    margin: 0 auto;
  }
}
</style>
