import { IPayedRestaurant } from "@/store/grading.store";
import apiClient from "@/utils/apiClient";
import cookie from "cookie";

const grading = async (id: string): Promise<IPayedRestaurant> => {
  const cookies = cookie.parse(document.cookie);
  const token = cookies.token;
  let res;
  if (token) {
    res = await apiClient.get(`/member-ladder/grading-list/${id}/private`);
  } else {
    res = await apiClient.get(`/member-ladder/grading-list/${id}`);
  }
  return res.data.data;
};
// моковые данные
// const grading = (id) => {
//   return {
//     type: "type",
//     id: "1",
//     attributes: {
//       id: "1",
//       title: "Мама Мия",
//       logo: "https://randd-dev.object.pscloud.io/logo/d6/26/594e9550f381a70567d5a6ca9f0a.png",
//       current_level: "2",
//       downgrade_date: "2022-11-18",
//       points: 210,
//       available_levels: [
//         {
//           id: "1", //от 0 до 200
//           value: 1,
//           threshold: 0,
//         },
//         {
//           id: "2", //от 200 до 300
//           value: 2,
//           threshold: 200,
//         },
//         {
//           id: "3", //от 300 до 400
//           value: 3,
//           threshold: 300,
//         },
//         {
//           id: "4", //от 400 до 500
//           value: 4,
//           threshold: 400,
//         },
//         {
//           id: "5", // свыше 500
//           value: 5,
//           threshold: 500,
//         },
//       ],
//       is_member: true,
//     },
//   };
// };
export const gradingRepository = {
  grading,
};
