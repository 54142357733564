
import { computed, defineComponent } from "vue";
import { useGrading } from "@/store/grading.store";
import GradePercent from "../components/GradePercent.vue";
import ProgressBar from "../components/ProgressBar.vue";

export default defineComponent({
  name: "MyGrade",
  components: {
    GradePercent,
    ProgressBar,
  },
  props: {
    leftDaysString:{
      type: String,
      required: true
    }
  },
  setup() {
    const gradingStore = useGrading();

    const currentLevel = computed(() => gradingStore.currentLevel);
    const availableLevels = computed(() => gradingStore.availableLevels);
    const currentLevelIndex = computed(() => gradingStore.currentLevelIndex);
    const points = computed(() => gradingStore.points);
    const currentLevelInArrayIndex = computed(
      () => gradingStore.currentLevelInArrayIndex
    );
    const findNextGradeLevel = computed(() => gradingStore.nextGradeLevel);
    const findPrevGradeLevels = computed(() => gradingStore.prevGradeLevel);
    const findLockedGradeLevels = computed(
      () => gradingStore.lockedGradeLevels
    );

    const nextThreshold = computed(() => gradingStore.nextThreshold);
    const remainingPointsToNextLevel = computed(
      () => gradingStore.remainingPointsToNextLevel
    );

    const checkIfLastLevel = computed(() => gradingStore.checkIfLastLevel);
    const downgradeDate = computed(() => gradingStore.downgradeDate);
    let currentLang = localStorage.getItem('language');
    const downgradeDateFormatted  = computed(() => gradingStore.downgradeDateFormatted);
    const daysBeforeLevelBurnsDown = computed(() => gradingStore.daysBeforeLevelBurnsDown);
    const currentThreshold = computed(() => gradingStore.currentThreshold);
    const pointsToSave = computed(() => gradingStore.pointsToSave);
    const isMember = computed(() => gradingStore.isMember);
    return {
      currentLevel,
      availableLevels,
      currentLevelIndex,
      currentLevelInArrayIndex,
      findNextGradeLevel,
      findPrevGradeLevels,
      findLockedGradeLevels,
      nextThreshold,
      remainingPointsToNextLevel,
      points,
      checkIfLastLevel,
      downgradeDate,
      downgradeDateFormatted,
      currentLang,
      daysBeforeLevelBurnsDown,
      currentThreshold,
      pointsToSave,
      isMember
    };
  },
});
