import amplitude from "amplitude-js";
import { useAnalytics } from "@/hooks/useAnalytics";
const amplitudeKey = process.env.VUE_APP_AMPLITUDE_KEY;
const isLocalEnv = process.env.VUE_APP_ENV === "local";
const isDev = process.env.VUE_APP_ENV !== "production";

export const Analytics = {
  init() {
    if (isLocalEnv) return;
    amplitude.getInstance().init(amplitudeKey!);
  },
  logEvent(event, payload = {}) {
    const data = payload;

    // Log event in development mode
    if (isDev) {
      console.log(event, data);
    }

    if (isLocalEnv) return;
    amplitude.getInstance().logEvent(event, data);
  },
};

export { default as events } from "./events";
