<template>
  <Achievements />
</template>

<script>
import { defineComponent } from "vue";
import Achievements from "@/components/Achievements.vue";
import { initAuth } from "@/plugins/checkAuth";
import { useGradingList } from "@/store/gradingList.store";
import { Analytics, events } from "@/service/analytics";

export default defineComponent({
  name: "HomePage",
  components: { Achievements },
  setup: async function () {
    await initAuth();
    await useGradingList().fetchRestaurantsList();
    Analytics.logEvent(events.GRADING_OPEN);
  }
});
</script>

