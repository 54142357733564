import axios, { AxiosResponse, AxiosError } from "axios";
import showAlertMessage from "@/utils/showAlertMessage";
import { uuid } from "@/plugins/uuid";
import cookie from "cookie";
import Auth from "@/utils/auth";
import RahmetApp from "@/RahmetApp";
import { useRouter } from "vue-router";
import {
  IApiProxyErrorData,
  isApiProxyErrorData,
} from "@/types/interfaces/api-proxy-error-data";

const apiClient = axios.create({
  // baseURL: process.env.VUE_APP_CHOCO_GATEWAY, // использовать локально + менять env
  baseURL: process.env.VUE_APP_CHOCO_API_PROXY,
});

/**
 * Очистка данных авторизации и обновление страницы
 */
const router = useRouter();
const logout = (): void => {
  cookie.serialize("token", "");
  router.push({ name: "Home" });
  window.location.reload();
};

/**
 * Обработка 401. Обернул в Promise, так как нужно время для обновления страницы/редиректа на Oauth
 */
const handleUnauthorizedResponse = (
  response: AxiosResponse | AxiosError
): Promise<any> => {
  return new Promise((resolve) => {
    if (
      Object.prototype.hasOwnProperty.call(response.config, "_retryAttempts")
    ) {
      return logout();
    }

    const config = { ...response.config, _retryAttempts: 1 };

    return RahmetApp.authorize().then((trackId) => {
      if (trackId) {
        return Auth.getAuthorizationWithTrackId(trackId.trackId)
          .then(() => resolve(apiClient(config)))
          .catch(() => logout());
      }

      return logout();
    });
  });
};

apiClient.interceptors.request.use(
  (config) => {
    if (config && config.headers) {
      config.headers["X-Idempotency-key"] = uuid();
      config.headers["X-Fingerprint"] = uuid();
      if (cookie.parse(document.cookie).token) {
        config.headers.Authorization = `Bearer ${
          cookie.parse(document.cookie).token
        }`;
      }
    }

    return config;
  },
  (reject) => Promise.reject(reject)
);

apiClient.interceptors.response.use(
  (response) => {
    if (response.data.error_code === 0) {
      return response;
    } else if (
      response.data.error_code === 401 ||
      response.data.error_code === 400
    ) {
      return handleUnauthorizedResponse(response);
    } else if (response.data.error_code > 0) {
      showAlertMessage("error", "Error", response.data.message);
    }

    return response;
  },
  (error: Error | AxiosError) => {
    if (axios.isAxiosError(error)) {
      // назначаю message первой ошибки в ApiProxy
      const data: IApiProxyErrorData | any = error.response?.data;
      if (isApiProxyErrorData(data) && data.errors?.length) {
        error.message = data.errors[0].title;
      }

      // обработка 401
      const statusCode = error.response?.status || 0;
      if (statusCode === 401 || statusCode === 400) {
        return handleUnauthorizedResponse(error);
      }
    }

    return Promise.reject(error);
  }
);

export default apiClient;
