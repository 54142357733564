import { defineStore } from "pinia";
import { gradingRepository } from "@/repository/grading";
import dayjs from "dayjs";
require("dayjs/locale/ru");
require("dayjs/locale/kk");

interface IAvailableLevel {
  id: string;
  value: number;
  threshold: number;
  lifetime: number;
}
export interface IPayedRestaurant {
  id: string;
  type: string;
  attributes: {
    id: string;
    title: string;
    logo: string;
    current_level: string;
    points: number;
    available_levels: Array<IAvailableLevel>;
    is_member: boolean;
    downgrade_date: string;
  };
}

interface State {
  restaurantDetails: null | IPayedRestaurant;
}

export const useGrading = defineStore("grading", {
  state: (): State => ({
    restaurantDetails: null,
  }),
  actions: {
    async fetchRestaurantDetails(id) {
      return await gradingRepository
        .grading(id)
        .then((response) => (this.restaurantDetails = response))
        .catch((error) => {
          throw error;
        });
    },
    // моковые данные
    // fetchRestaurantDetails(id) {
    //   this.restaurantDetails = gradingRepository.grading(id);
    // },
  },
  getters: {
    title: (state): string | undefined =>
      state.restaurantDetails?.attributes?.title,
    currentLevel: (state): string | undefined =>
      state.restaurantDetails?.attributes?.current_level,
    logo: (state): string | undefined =>
      state.restaurantDetails?.attributes?.logo,
    downgradeDate: (state): string | undefined =>
      state.restaurantDetails?.attributes?.downgrade_date,
    availableLevels: (state) =>
      state.restaurantDetails?.attributes?.available_levels,
    isMember: (state): Boolean | undefined =>
      state.restaurantDetails?.attributes?.is_member,
    currentLevelIndex(state): number | undefined {
      return this.availableLevels?.find(
        (el) => el.id == state.restaurantDetails?.attributes?.current_level
      )?.value;
    },
    currentLevelInArrayIndex(state): number {
      return (
        state.restaurantDetails?.attributes?.available_levels?.findIndex(
          (x) => {
            return x.value === this?.currentLevelIndex;
          }
        ) || 0
      );
    },
    nextGradeLevelObj(): any | undefined {
      if (!this.availableLevels) return undefined;
      if (!this.availableLevels[this?.currentLevelInArrayIndex + 1])
        return undefined;

      return this.availableLevels[this?.currentLevelInArrayIndex + 1];
    },
    nextGradeLevel(): number | undefined {
      if (!this.availableLevels) return undefined;
      if (!this.availableLevels[this?.currentLevelInArrayIndex + 1])
        return undefined;

      return this.availableLevels[this?.currentLevelInArrayIndex + 1].value;
    },
    prevGradeLevel(state): number[] | undefined {
      if (!this.availableLevels) return undefined;
      if (!this.currentLevelInArrayIndex) return undefined;

      return this?.availableLevels
        .slice(0, this.currentLevelInArrayIndex)
        .map((el) => el.value);
    },
    points: (state) => state.restaurantDetails?.attributes?.points,
    lockedGradeLevels(): number[] | undefined {
      if (!this.availableLevels) {
        return undefined;
      }
      return this.availableLevels
        .filter((item, index) => this.currentLevelInArrayIndex + 2 <= index)
        .map((el) => el.value);
    },
    checkIfLastLevel(state) {
      if (state.restaurantDetails?.attributes?.available_levels?.length) {
        if (
          this?.currentLevelInArrayIndex ===
          state.restaurantDetails?.attributes?.available_levels?.length - 1
        ) {
          return true;
        }
      }
      return false;
    },
    nextThreshold(): number | undefined {
      if (!this.availableLevels) return undefined;
      if (this.checkIfLastLevel) return this.currentThreshold;
      else
        return this.availableLevels[this?.currentLevelInArrayIndex + 1]
          .threshold;
    },
    remainingPointsToNextLevel(): number | undefined {
      if (!this.availableLevels) {
        return undefined;
      }
      if (!this.nextThreshold) return undefined;
      if (!this.points && this.points !== 0) {
        return undefined;
      }
      let points: any = null;
      if (this.checkIfLastLevel) points = this.nextThreshold - this.points;
      else {
        points =
          this.availableLevels[this.currentLevelInArrayIndex + 1].threshold -
          this.points;
      }
      if (points <= 0) return 0;
      else return points;
    },
    currentThreshold(): number | undefined {
      if (!this.availableLevels) return undefined;
      if (!this.availableLevels[this.currentLevelInArrayIndex])
        return undefined;
      return this.availableLevels[this.currentLevelInArrayIndex].threshold;
    },
    currentLifetime(): number | undefined {
      if (!this.availableLevels) return undefined;
      if (!this.availableLevels[this.currentLevelInArrayIndex])
        return undefined;
      return this.availableLevels[this.currentLevelInArrayIndex].lifetime;
    },
    currentLifetimeString(): string | undefined {
      if (!this.currentLifetime) return undefined;
      const formatter = new Intl.NumberFormat("ru", {
        style: "unit",
        unit: "day",
        unitDisplay: "long",
      });
      return formatter.format(this.currentLifetime);
    },
    nextGradeLevelValue(): number | undefined {
      if (!this.availableLevels) return undefined;
      if (!this.availableLevels[this.currentLevelInArrayIndex + 1])
        return undefined;
      return this.availableLevels[this.currentLevelInArrayIndex + 1].value;
    },
    checkIfLevelPassed(): boolean | undefined {
      if (!this.points) return undefined;
      if (!this.availableLevels) return undefined;
      if (!this.currentLevelIndex) return undefined;
      const totalPoints = this.points;
      let threshold;
      if (this.availableLevels[this.currentLevelInArrayIndex + 1]) {
        threshold =
          this.availableLevels[this.currentLevelInArrayIndex + 1].threshold;
      }

      if (threshold) return totalPoints >= threshold;
      return undefined;
    },
    pointsToSave(): number | undefined {
      if (!this.points && this.points !== 0) return undefined; // check- in web-payments was a problem - added && this.points !== 0
      if (!this.availableLevels) return undefined;
      if (!this.currentLevelInArrayIndex && this.currentLevelInArrayIndex !== 0)
        // check - added && currentLevelInArrayIndex !== 0
        return undefined;
      const calculatedPointsToSave =
        this.availableLevels[this.currentLevelInArrayIndex].threshold -
        this.points;
      if (calculatedPointsToSave <= 0) return undefined;
      else return calculatedPointsToSave;
    },
    daysBeforeLevelBurnsDown(): number | undefined {
      if (!this.downgradeDate) return undefined;
      const currentDate = dayjs(new Date()).format("YYYY-MM-DD");
      return Math.ceil(
        (Date.parse(this.downgradeDate) - Date.parse(currentDate)) /
          (1000 * 3600 * 24)
      );
    },
    downgradeDateFormatted(): string | undefined {
      let formatted = "";
      if (localStorage.getItem("language") === "ru") {
        formatted = dayjs(this.downgradeDate).locale("ru").format("D MMMM");
      } else if (localStorage.getItem("language") === "kk") {
        formatted = dayjs(this.downgradeDate).locale("kk").format("D MMMM");
      }
      if (
        formatted.includes("қаңтар") ||
        formatted.includes("ақпан") ||
        formatted.includes("наурыз") ||
        formatted.includes("мамыр") ||
        formatted.includes("маусым") ||
        formatted.includes("тамыз") ||
        formatted.includes("қазан") ||
        formatted.includes("қараша") ||
        formatted.includes("желтоқсан")
      ) {
        formatted = formatted + "ға";
      } else if (formatted.includes("сәуір" || "шілде")) {
        formatted = formatted + "ге";
      } else if (formatted.includes("қыркүйек")) {
        formatted = formatted + "ке";
      }
      return formatted;
    },
    leftDaysString(): string | undefined {
      if (!this.daysBeforeLevelBurnsDown) return undefined;
      const formatter = new Intl.NumberFormat("ru", {
        style: "unit",
        unit: "day",
        unitDisplay: "long",
      });
      const leftDays = formatter.format(this.daysBeforeLevelBurnsDown);

      let leftWord;
      if (leftDays.includes("дней") || leftDays.includes("дня")) {
        leftWord = "осталось";
      } else if (leftDays.includes("день")) {
        leftWord = "остался";
      }
      let leftDaysString;
      return (leftDaysString = `${leftWord} ${leftDays}`);
    },
  },
});
