import cookie from "cookie";
import apiClient from "@/utils/apiClient";
import showAlertMessage from "@/utils/showAlertMessage";
import RahmetApp from "@/RahmetApp";
import { AxiosResponse } from "axios";

interface AuthDataResponse {
  expires_in: 3600;
  refresh_token: string;
  access_token: string;
  token_type: string;
}

export interface AuthResponse {
  data: AuthDataResponse | null;
  error_code: number;
  message: string;
  status: string;
}

export default class Auth {
  protected trackId: string | null;

  constructor(trackId: string | null) {
    this.trackId = trackId;
  }

  private static async authorizationWithTrackId(
    trackId: string
  ): Promise<AxiosResponse<AuthResponse> | void> {
    try {
      const response = await apiClient.post(
        // `${process.env.VUE_APP_CHOCO_GATEWAY}/auth/token`, // использовать локально + добавлять track_id
        `${process.env.VUE_APP_HOST_API_PROXY}api/v2/oauth2/tokens`,
        {
          client_id: process.env.VUE_APP_CLIENT_ID,
          grant_type: "authorization_track_id",
          track_id: trackId,
        }
      );

      document.cookie = cookie.serialize("token", response.data.access_token, {
        path: "/",
        maxAge: response.data.expires_in,
      });
      return response;
    } catch (err: any) {
      console.log("ERROR - Произошла ошибка при получении access token");
    }
  }

  private static async authorizationWithNativeApp(): Promise<AxiosResponse<AuthResponse> | void> {
    return RahmetApp.authorize().then(async (res) =>
      Auth.authorizationWithTrackId(res.trackId)
    );
  }

  private static async authorizationForLocal() {
    try {
      const response = await apiClient.post(
        `${process.env.VUE_APP_HOST_API_PROXY}auth/token`,
        {
          user_id: "14121989",
          client_id: "11557511",
          grant_type: "password",
          password: "9f3Fjezgym",
        }
      );
      document.cookie = cookie.serialize("token", response.data.data.token, {
        path: "/",
        maxAge: response.data.data.expire_in,
      });
      const res = await apiClient.post(
        `${process.env.VUE_APP_HOST_API_PROXY}auth/track`,
        {},
        {
          headers: {
            Authorization: `Bearer ${response.data.data.token}`,
          },
        }
      );

      return Auth.authorizationWithTrackId(res.data.data.track_id);
    } catch (err: any) {
      return showAlertMessage(
        "error",
        "Произошла ошибка при получении access token",
        err
      );
    }
  }

  static getAuthorizationWithTrackId(trackId) {
    return Auth.authorizationWithTrackId(trackId);
  }

  async init() {
    if (this.trackId) {
      return Auth.authorizationWithTrackId(this.trackId);
    }
    if (window.RahmetApp && window.RahmetApp.authorize) {
      return Auth.authorizationWithNativeApp();
    }
    return Auth.authorizationForLocal();
  }
}
