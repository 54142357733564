import { createApp } from "vue";
import RahmetApp from "@/RahmetApp";
import App from "@/App.vue";
import router from "@/router";
import { createPinia } from "pinia";
import i18n from "@/service/i18n";
import { Analytics } from "@/service/analytics";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

declare global {
  interface Window {
    RahmetApp: any;
    RahmetWebApp: any;
  }
}

RahmetApp.setupWebViewApi();

const pinia = createPinia();
const app = createApp(App);
if (
  process.env.VUE_APP_ENV === "production" ||
  process.env.VUE_APP_ENV === "staging"
) {
  Sentry.init({
    app,
    dsn: "https://99347649c3524ffc8382ecf5e4d32b04@sentry.chocodev.kz/335",
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [
          "grading-front.chocodev.kz/",
          "grading-front.choco.kz",
          /^\//,
        ],
      }),
    ],
    environment: `Grading-front ${
      process.env.VUE_APP_ENV === "production" ? "Prod" : "Stage"
    }`,
    tracesSampleRate: 1.0,
  });
}

Analytics.init();
app.use(router);
app.use(pinia);
app.use(i18n).mount("#app");
