import { defineStore } from "pinia";
import { gradingListRepository } from "@/repository/gradingList";
interface State {
  restaurantsList: any;
}

export const useGradingList = defineStore("gradingList", {
  state: (): State => ({
    restaurantsList: null,
  }),
  actions: {
    async fetchRestaurantsList() {
      return await gradingListRepository
        .gradingList()
        .then((response) => (this.restaurantsList = response))
        .catch((error) => {
          throw error;
        });
    },
    // моковые данные
    // async fetchRestaurantsList() {
    //   this.restaurantsList = await gradingListRepository.gradingList();
    // },
  },
  getters: {
    sortedRestaurantsList(): [] | undefined {
      if (!this.restaurantsList) {
        return [];
      } else {
        return this.restaurantsList.sort(function (x, y) {
          return x.attributes.is_member === y.attributes.is_member
            ? 0
            : x.attributes.is_member
            ? -1
            : 1;
        });
      }
    },
  },
});
