function connect<Type>(callback: any): Promise<Type> {
  return new Promise((resolve, reject) => {
    try {
      callback(resolve, reject);
    } catch (err) {
      reject(err);
    }
  });
}

interface IAuth {
  trackId: string;
  deviceId: string;
}

const RahmetApp = {
  authorize() {
    return connect<IAuth>((res: any) => {
      window.RahmetApp.authorize();
      window.RahmetWebApp.onAuthSuccess = (
        trackId: string,
        deviceId: string
      ) => {
        res({ trackId, deviceId });
      };
    });
  },
  backToRahmetApp() {
    window.RahmetApp.backToRahmetApp();
  },
  setupWebViewApi() {
    window.RahmetWebApp = {
      backToRahmetApp() {},
      didBecomeActive() {},
    };
  },
};

export default RahmetApp;
